import { tokenKey } from '~/constants/auth'

export default defineNuxtRouteMiddleware(() => {
  const token = useStatefulCookie(tokenKey)

  if (!token.value) {
    return navigateTo({
      name: 'login',
    })
  }
})
